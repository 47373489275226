<template>
  <div class=container>
      <table class="table" border="0" cellpadding="0" cellspacing="30" width="709">
<tbody><tr>
<td>
  <div align="center">
  <font size="+2"></font>
  </div>

<br>
<br>
<h3>Database Organization&nbsp;</h3>

The release of the database includes 2 parts for each
scanned sample: the raw measurements, and the fitted BRDF parameters.
The TVBRDF database consists of a total of 41 <router-link to="/repository/TVBRDF/download">
samples</router-link> each of which is denoted by a unique sample ID. 
The structure of the database, for sample01, is as follows:<br>

<br>
<table style="text-align: left; width: 100%;" border="1" cellpadding="2" cellspacing="2">
  <tbody>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">sample01/</td>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
    </tr>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>

      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">tvBrdf-data/</td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
      <td style="font-family: Courier New,Courier,monospace;">HDR radiance measurements</td>
    </tr>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">tvBrdf-fits/</td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
      <td style="font-family: Courier New,Courier,monospace;">Fit BRDF model parameters</td>
    </tr>
    <tr>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);"></td>
      <td style="font-family: Courier New,Courier,monospace; color: rgb(0, 0, 153);">tvBrdf-info.txt</td>
      <td style="font-family: Courier New,Courier,monospace;"></td>
      <td style="font-family: Courier New,Courier,monospace;">Information about the scan process</td>
    </tr>
  </tbody>
</table>

<br>
<br>

<hr style="width: 100%; height: 2px;">
<h3>Database Details</h3>
The following is a detailed description of all the data released:<br>
<br>

<ul>
  <font style="color: rgb(0, 0, 153);">
  <li><strong>tvBrdf-info.txt</strong></li>
  </font>
  This file contains information about the scanning prodecure for each sample. 
  This includes:
  <pre>    1) Sample Name (Process)
    2) Exposures used to generate HDR radiance data
    3) Number of scans
    4) Time stamp of each scan
  </pre>

  <font style="color: rgb(0, 0, 153);">
  <li><strong>tvBrdf-data/</strong></li>
  </font>
  This folder contains the HDR radiance measurements for the sample. As 
  explained here we average radiance values over a patch of the sample 
  to compute the aggregate BRDF. This is done for three different 
  exposures that are combined to produce the measurements in this folder. 
  The text files here are named<br>
  
  <br>
  <center style="color: rgb(0, 0, 153);">
    <strong>scanTT_brdf_cameraV_allexposures.txt</strong>
  </center>
  
  <br>
  where <font style="color: rgb(0, 0, 153);">TT</font> is the scan index 
  (from 01 to the number of time this sample was scanned), 
  <font style="color: rgb(0, 0, 153);">V</font> is the camera index (from 
  1 to 4, see <router-link to="/repository/TVBRDF/documentation">here</router-link> for an explanation of the
  rig).<br>

  <br>
  There are thus number of views (4) X number of scans (sample specific)
  files in this folder and each file stores the BRDF for all lighting
  directions. The first line of each file lists the exposures used for the
  sample. Each set of three lines following this has the radiance values 
  (B G R), the lighting direction (LX LY LZ) and the viewing direction 
  (VX VY VZ). For example for sample01 scan01_brdf_camera1_allexposures.txt 
  looks like<br>
  
  <pre style="background-color: rgb(204, 204, 204);">Exposure 0.200000 4.000000 32.000000<br>F 0.669680 0.778409 0.834711<br>L 0.978148 0.000000 0.207912<br>V -0.207912 0.000000 0.978148<br></pre>

  This means that the radiance for this sample at lighting direction
  &lt;0.978148, 0.000000, 0.207912&gt; and viewing direction 
  &lt;-0.207912, 0.000000, 0.978148&gt; the radiance is (0.834711, 0.778409, 0.669680).<br>

  <br>
  These measurements need to be radiometrically calibrated to compensate for
  both the light source and the camera response. For this we
  used a spectralon sample with albedo(B, G, R) = (0.7410, 0.7438, 0.752).
  The radiance measurements for the spectralon sample are <a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/files/spectralon.zip">
  here</a>. We fit a Lambertian BRDF model to the radiance measurements of the 
  spectralon and have computed the normalization constants for each camera
  and each color channel. These can be accessed <a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/files/norms.txt">here</a>.
  The four lines of this text file have the values (B, G, R) that the radiance 
  measurements from each camera need to be divided by for each color channel.<br>

  <br>
  <font style="color: rgb(0, 0, 153);">
  <li><strong>tvBrdf-fits/</strong></li>
  </font>
  We have fit analytic BRDF models to our measurements at each time instant. 
  This folder contains the file<br>
  <center style="color: rgb(0, 0, 153);">
    <strong>brdf-params.txt</strong>
  </center>

  <br>
  Each line of this file has fit parameters for one scan. <br>
  <br>

  For the paint, drying and miscellaneous samples (sample01 - sample27 and sample40 - sample41)
  we have fit a combination of the Oren-Nayar diffuse BRDF and the Torrance-Sparrow specular 
  BRDF models. For these samples <font style="color: rgb(0, 0, 153);">brdf-params.txt</font>
  has 6 parameters at each time instant. These are, in order 
  <font style="color: rgb(0, 0, 153);">K_d(B, G, R)</font>,
  <font style="color: rgb(0, 0, 153);">sigma_d</font>,
  <font style="color: rgb(0, 0, 153);">K_s</font> and
  <font style="color: rgb(0, 0, 153);">1 / sigma_s</font>,
  <br>

  <br>
  For the dust samples (sample28 - sample39)
  we have fit a combination of Blinn's Dust BRDF and the Torrance-Sparrow specular 
  BRDF models. For these samples <font style="color: rgb(0, 0, 153);">brdf-params.txt</font>
  has 11 parameters at each time instant. These are, in order 
  <font style="color: rgb(0, 0, 153);">K_d(B, G, R)</font>,
  <font style="color: rgb(0, 0, 153);">sigma_d</font>,
  <font style="color: rgb(0, 0, 153);">K_s</font>,
  <font style="color: rgb(0, 0, 153);">1 / sigma_s</font>,
  <font style="color: rgb(0, 0, 153);">w_dust(B, G, R)</font>,
  <font style="color: rgb(0, 0, 153);">g</font> and
  <font style="color: rgb(0, 0, 153);">tau</font>
  <br>
  <br>
  For a detailed explanation of each model and parameter please look at the following paper and its appendices: 
<blockquote>
<!--?
print_paperentry_byid($db,"264",false);
?-->
</blockquote>

  <br>
  The fitted model enables us to directly render the measured materials' appearance over time. The following video
  shows a teapot rendered with our measurements of the BRDF of blue watercolor on white paper. Note the change in 
  the fit model parameters over time and the resulting change in the appearance.<br>

  <br>
  <div style="text-align: center;">
  <a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/videos/slides_teapot_varying_parameters_comp.avi">
  <img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/format_files/drying_teapot.png" border="0" style="width: 423px; height: 317px;">  </a>  </div>



</ul>

</td>
</tr>
          
<tr>
  <td>
  <hr style="width: 100%; height: 2px;">
  <router-link to="/repository/TVBRDF">TVBRDF Database Home</router-link><br>
  Contact:&nbsp;<a href="mailto:tvbrdf@cs.columbia.edu">tvbrdf@cs.columbia.edu</a><br>
  Last modified: 06/03/2007
  </td>
</tr>

</tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>